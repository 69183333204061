import React, {Fragment} from "react";
import ReactDOM from 'react-dom';

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import MenuDrawer from '@components/MenuDrawer';
import SearchBar from "@components/Header/SearchBar";

// DatePicker
import "react-datepicker/dist/react-datepicker.css";

// HELPERS
import {
	createB64Context,
	createClassName,
	stringifyQueryParams,
	formatSSN,
	checkIsLaptopOrDesktop
} from "@helpers/utils";

// OTHER
import DashboardSessions from "../../Dashboard/DashboardSessions";
import DashboardFutur from "../../Dashboard/DashboardFutur";
import DashboardPoas from "../../Dashboard/DashboardPoas";
import DashboardKycs from "../../Dashboard/DashboardKycs";
import DashboardOther from "../../Dashboard/DashboardOther";
import DashboardReports from "../../Dashboard/DashboardReports/";

import fulfillment from "./Start.fulfillment";
import InitSession from "./InitSession/InitSession";
import CustomersTable from "../../../components/CustomersTable";
import UploadAndSign from "../../../components/UploadAndSign/UploadAndSign";
import Fullmaktskollen from "../../../components/Fullmaktskollen/Fullmaktskollen";

export default class Start extends React.PureComponent
{
	state = _getInitialState.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			// TODO: refactor below to appCustomerState to avoid confusions
			this.props.setAppState({customerState, viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	async componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {display: false},
				next: {label: "Påbörja", disabled: true}
			}
		});
	}

	componentDidUpdate(prevProps, prevState)
	{
		const {props, state} = this;
		const {setAppState, user, customer, navigationButtons, partner} = props;
		const {customerState} = state;
		const updatedStateProps = {
			selectedCaseId: prevState.customerState.selectedCaseId !== state.customerState.selectedCaseId,
			isNewCase: prevState.customerState.isNewCase !== state.customerState.isNewCase,
			standaloneApplicationsChecked: prevState.customerState.standaloneApplicationsChecked !== state.customerState.standaloneApplicationsChecked
		};
		const newCustomerState = {};
		const newAppStateProps = {};

		if (!!customer && prevProps.customer !== customer)
		{
			newCustomerState.isCompany = customer.isCompany;
			newCustomerState.ssnOrg = customer.getData("customer_social_security_number");
		}

		if (updatedStateProps.selectedCaseId || updatedStateProps.isNewCase || updatedStateProps.standaloneApplicationsChecked)
		{
			// TOCHECK: if this code can be removed as we no longer have the footer on the start page (moved this logic to Cob.jsx _onFormSubmitAsync function for InitSession's form submit)
			newAppStateProps.navigationButtons = {
				...navigationButtons,
				next: {
					...navigationButtons.next,
					disabled: !!customerState.selectedCaseId === customerState.isNewCase && customerState.standaloneApplicationsChecked.length === 0,
					willSubmitForm: customerState.standaloneApplicationsChecked.length === 0,
					onClick: customerState.standaloneApplicationsChecked.length === 0 ? undefined : () =>
					{

						let standaloneApp = null;
						for (let i = 0; i < partner.standaloneApplications.length; i++)
						{
							if (partner.standaloneApplications[i].label === customerState.standaloneApplicationsChecked[0])
							{
								standaloneApp = partner.standaloneApplications[i];
								break;
							}
						}

						if (standaloneApp === null)
						{
							console.error("Invalid app configuration", customerState.standaloneApplicationsChecked);
							return;
						}

						if (!customerState.ssnOrg)
						{
							console.error("Cannot proceed to standalone application without a customer ssn");
							return;
						}

						// Create a checksum that the cob odin client will check to make sure that the request
						// originated in a correct way.
						const encodedContext = createB64Context("democlient", user, formatSSN(customerState.ssnOrg, {withDash: false}), null);
						window.location.href = standaloneApp.url + stringifyQueryParams({
							context: encodedContext,
							token: Settings.get("AUTH_TOKEN"),
							key: standaloneApp.key
						});
					}
				}
			};
		}

		if (Object.keys(newCustomerState).length > 0)
		{
			this.setState({isLoading: false}, () => this.setCustomerState(newCustomerState));
		}

		if (Object.keys(newAppStateProps).length > 0)
		{
			setAppState(newAppStateProps);
		}
	}

	render()
	{
		const {props, state, setCustomerState} = this;
		const {busy, user, partner, customers, assentlyStatus, advisor, cobForm, onWorkflowSwitched} = props;
		const {customerState, dashboardSelected} = state;
		const classes = createClassName("Cob-view Cob-view1 start", {});
		const isLaptopOrDesktop = checkIsLaptopOrDesktop();

		const nextStepOptions = [
			{
				label: "Skapa ett nytt rådgivningstillfälle",
				key: "new",
				type: "case",
				checked: customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === true,
				disabled: !user.hasPermission("case.create")
			},
			{
				label: "Hämta ett tidigare rådgivningstillfälle",
				key: "drafts",
				type: "case",
				checked: customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === false,
				disabled: !user.hasPermission("case.edit")
			}
		];

		/**
		 * Add links to all standalone applications (autogiro, kontoöppning, futur etc)
		 */
		for (let i = 0; i < partner.standaloneApplications.length; i++)
		{
			const standaloneApplication = partner.standaloneApplications[i];

			let optionDisabled = !user.hasPermission("case.create");

			if ("disableRule" in standaloneApplication)
			{
				if (standaloneApplication.disableRule === 'Always' || optionDisabled)
				{
					optionDisabled = true;
				}
				else if (standaloneApplication.disableRule === 'FuturCode')
				{

					/**
					 * If the logged in user does not have a FuturCode we disable this option
					 */
					optionDisabled = user.data['futurCode'] === null || user.data['futurCode'] === '';

					/**
					 * If we have a FuturCode we check if the ssn entered was for a private individual or a company.
					 * At the moment only companies are permitted to open accounts. These need to have an org number
					 * that starts with a 5
					 */
					if (!optionDisabled)
					{
						if (customerState.isCompany)
						{
							let orgNrNotAktiebolag = true;
							if (customerState.ssnOrg && customerState.ssnOrg.length > 3)
							{
								if (customerState.ssnOrg.substring(0, 1) === "5" ||
									(customerState.ssnOrg.substring(0, 2) === "16" &&
										customerState.ssnOrg.substring(3, 4) === "5"))
								{
									orgNrNotAktiebolag = false;
								}
							}
							optionDisabled = orgNrNotAktiebolag;
						}
						else
						{
							// Allow also private individuals
							optionDisabled = false;
						}
					}
				}
				else
				{
					console.error("Unhandled disable rule for option ", standaloneApplication);
				}
			}

			nextStepOptions.push({
				label: standaloneApplication.label,
				key: standaloneApplication.key,
				type: "standaloneApp",
				checked: customerState.standaloneApplicationsChecked.includes(standaloneApplication.label),
				disabled: optionDisabled
			});
		}

		const dashboards = {
			DashboardSessions: {
				component: DashboardSessions,
			},
			DashboardPoas: {
				component: DashboardPoas,
			},
			DashboardFutur: {
				component: DashboardFutur,
			},
			DashboardKycs: {
				component: DashboardKycs,
			},
			DashboardOther: {
				component: DashboardOther,
			},
			DashboardReports: {
				component: DashboardReports,
			},
		};

		const dashboardWidgets = partner.modules.find(module => module.key === dashboardSelected)?.widgets;

		return (
			<div className={classes}>
				<MenuDrawerPortalWrapper
					dashboardSelected={dashboardSelected}
					user={user}
					modules={partner.modules}
					onToggle={this._onToggleDashboard}
					setCaseFilter={this._setCaseFilter}
				/>
				{isLaptopOrDesktop && (
					<HeaderSearchPortalWrapper
						advisor={advisor}
						partner={partner}
						customers={customers}
						cobForm={cobForm}
						nextStepOptions={nextStepOptions}
						setCustomerState={setCustomerState.bind(this)}
					/>
				)}
				{state.isLoading ? (
					<ActivityIndicator color={partner?.theme.colors.primary.main} busy style={{left: "-12.5%"}}/>
				) : (
					<div
						className="inline-widget"
					>
						{dashboardWidgets && dashboardWidgets.map((widget, index) =>
						{
							const DashboardWidget = dashboards[widget.component]?.component;

							return (
								<Fragment key={index}>
									{DashboardWidget && (
										<DashboardWidget
											showHeader={widget.showHeader}
											user={user}
											advisor={advisor}
											partner={partner}
											cobForm={cobForm}
											customerState={customerState}
											setCustomerState={setCustomerState.bind(this)}
											caseFilterContext={{
												caseFilter: state.caseFilter || null,
												setCaseFilter: this._setCaseFilter,
												cobForm,
											}}
											caseFilter={state.caseFilter}
											onShareOpen={props.onShareOpen}
											setCaseFilter={this._setCaseFilter}
											nextStepOptions={nextStepOptions}
											onWorkflowSwitched={onWorkflowSwitched}
										/>
									)}
									{widget.component === "CustomersTable" && (
										<CustomersTable
											inline
											showHeader={widget.showHeader}
											width={widget.width}
											partner={partner}
											user={user}
											advisor={advisor}
											customers={customers}
											setCustomerState={setCustomerState.bind(this)}
											caseFilterContext={{
												caseFilter: state.caseFilter || null,
												setCaseFilter: this._setCaseFilter,
												cobForm,
											}}
											nextStepOptions={nextStepOptions}
											onWorkflowSwitched={onWorkflowSwitched}
										/>
									)}
									{widget.component === "InitSession" && (
										<InitSession
											inline
											showHeader={widget.showHeader}
											width={widget.width}
											busy={busy}
											user={user}
											advisor={advisor}
											partner={partner}
											customerState={customerState}
											setCustomerState={setCustomerState.bind(this)}
											assentlyStatus={assentlyStatus}
											nextStepOptions={nextStepOptions}
										/>
									)}
									{widget.component === "UploadAndSign" && user.hasPermission("standalone.send") && (
										<UploadAndSign
											widget
											inline
											showHeader={widget.showHeader}
											width={widget.width}
											partnerSsn={advisor.ssn}
										/>
									)}
									{widget.component === "Fullmaktskollen" && user.hasPermission("fmk.send") && (
										<Fullmaktskollen
											inline
											widget
											showHeader={widget.showHeader}
											width={widget.width}
											customerIdentityNumber={customerState.ssnOrg ? formatSSN(customerState.ssnOrg, {withDash: false}) : ""}
											user={advisor}
										/>
									)}
								</Fragment>
							);
						})}
					</div>
				)}
				<>
					<input type="hidden" name="customer_social_security_number"
					       value={customerState.ssnOrg ? formatSSN(customerState.ssnOrg, {withDash: false}) : ""}/>
					<input type="hidden" name="checkbox_company" value={customerState.isCompany ? "yes" : "no"}/>
					<input type="hidden" name="caseId" value={customerState.selectedCaseId || ""}/>
				</>
			</div>
		);
	}

	_onToggleDashboard = option =>
	{
		const initialState = _getInitialState.call(this);
		this.setState({...initialState, dashboardSelected: option});
		this.props.setAppState({customerState: initialState.customerState, dashboardSelected: option});
	};

	_setCaseFilter = (dashboard, filter) =>
	{
		// If no dashboard or filter is provided, reset the case filter
		if (!dashboard || !filter)
		{
			return this.setState({
				caseFilter: {
					sessions: "all",
					poa: "all",
					futur: "all",
					kyc: "expired",
					other: "all"
				}
			});
		}

		this.setState({
			caseFilter: {
				...this.state.caseFilter,
				[dashboard]: filter
			}
		});
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, partner} = this.props;
	let newState = getViewInitialStateForCustomer(customer, partner);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer, partner)
{
	let initialState = {
		isLoading: false,
		dashboardSelected: "start",
		isFulfilled: false,

		customerState: {
			isCompany: customer ? customer.isCompany : false,
			ssnOrg: (!!customer && customer.getData("customer_social_security_number")) || "",
			isNewCase: null,
			casesLoading: false,
			cases: null,
			selectedCaseId: null,
			selectedCaseIndex: -1,
			standaloneApplicationsChecked: [],
			kycCaseId: null,
			kycInfoLoading: false,
			kycDate: null,
			needsNewDocumentation: false,
			overrideNewDocumentation: false
		},

		caseFilter: {
			sessions: "all",
			poa: "all",
			futur: "all",
			kyc: "expired",
			other: "all"

		}
	};

	if (partner != null && !partner.modules.includes("dashboard"))
	{
		initialState.dashboardSelected = "start";
	}

	return initialState;
}

// Create a wrapper outside the main React component hierarchy using React Portals
const MenuDrawerPortalWrapper = (props) =>
{
	return ReactDOM.createPortal(
		<MenuDrawer
			user={props.user}
			modules={props.modules}
			onToggle={props.onToggle}
			setCaseFilter={props.setCaseFilter}
		/>,
		document.getElementById('leApp')
	);
};
const HeaderSearchPortalWrapper = (props) =>
{
	const {advisor, partner, customers, cobForm, nextStepOptions, setCustomerState} = props;

	if (partner.headerWidgets.includes("SearchBar"))
	{
		return ReactDOM.createPortal(
			<SearchBar
				advisor={advisor}
				partner={partner}
				customers={customers}
				cobForm={cobForm}
				nextStepOptions={nextStepOptions}
				setCustomerState={setCustomerState}
			/>,
			document.getElementById('searchBarContainer')
		);
	}
};