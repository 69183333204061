import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {
	state = mergeViewStateForFulfillment(state, prevState);

	// One of the dropdown options need to be selected
	if (state.customerState.isNewCase === null) {
		return false;
	}

	return true;
}

export function inputIsCompany(input) {
	// trim spaces
	input = input.replace(/\s/g, "");

	// check if 5th character from the end is a dash and trim it
	if (input[input.length - 5] === "-") {
		input = input.replace(/-/g, "");
	}

	if (input.length === 12 || input.length === 13) {
		return input.substring(0, 2) === "16" && input[4] >= 2;
	}

	return input.length === 10 && input[2] >= 2;
}